body {
  /* background: linear-gradient(to bottom, 
    #F4A460 0%, #F4A460 20%, 
    #87CEEB 25%, #87CEEB 50%, 
    #1E90FF 55%, #1E90FF 75%, 
    #00008B 80%, #00008B 100%
  ); */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  line-height: 1.5;
}
